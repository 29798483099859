import * as React from "react";

import { Page, Section } from "../components";

export default function MentionsLegales() {
  return (
    <Page tabTitle="Mentions légales" pageTitle="Mentions légales">
      <Section title="Éditeur du site internet">
        <ul className="content">
          <li>
            <strong>Nom de l’entreprise&nbsp;:</strong> KerNel Biomedical SAS
            (Société par Actions Simplifiée)
          </li>
          <li>
            <strong>Adresse&nbsp;:</strong> 9 rue du 74e Régiment d’Infanterie,
            76100 Rouen
          </li>
          <li>
            <strong>RCS&nbsp;:</strong> Rouen n°823 905 898
          </li>
          <li>
            <strong>N° TVA intracommunautaire&nbsp;:</strong> FR81823905898
          </li>
          <li>
            <strong>Capital social&nbsp;:</strong> 30 589,00 €
          </li>
          <li>
            <strong>E-mail&nbsp;:</strong>{" "}
            <a href="mailto:contact@kernelbiomedical.com">
              contact@kernelbiomedical.com
            </a>
          </li>
          <li>
            <strong>Représentant légal&nbsp;:</strong> Adrien Kerfourn,
            Président
          </li>
          <li>
            <strong>Directrice de publication&nbsp;:</strong> Émeline Fresnel,
            Directrice Générale
          </li>
          <li>
            <strong>
              Contact du délégué à la protection des données&nbsp;:
            </strong>{" "}
            <a href="mailto:dpo@kernelbiomedical.com">
              dpo@kernelbiomedical.com
            </a>
          </li>
        </ul>
      </Section>
      <Section title="Hébergement du site internet" column limited justify>
        <p>
          Le site{" "}
          <a href="https://dotter.science" target="_blank">
            dotter.science
          </a>{" "}
          ou{" "}
          <a href="https://www.dotter.science" target="_blank">
            www.dotter.science
          </a>{" "}
          est hébergé par Netlify, Inc. (44 Montgomery Street, Suite 300, San
          Francisco, California 94104). Conformément à l'article 27 du Règlement
          général sur la protection des données (RGPD) de l'Union européenne
          (UE), VeraSafe (Unit 3D North Point House, North Point Business Park,
          New Mallow Road, Cork T23AT2P, Ireland) a été nommé représentant de
          Netlify dans l'Union européenne et au Royaume-Uni pour les questions
          de protection des données.
        </p>
        <p>
          <strong>
            L’application Dotter.science, disponible à l’adresse{" "}
            <a href="https://hds.dotter.science" target="_blank">
              hds.dotter.science
            </a>
            , est hébergée par AZNETWORK (40 rue Ampère, 61000 ALENCON, France).
            AZNETWORK est certifié pour l’hébergement de données de santé (HDS).
          </strong>
        </p>
        <p>
          L’instance de démonstration, disponible à l’adresse{" "}
          <a href="https://demo.dotter.science" target="_blank">
            demo.dotter.science
          </a>
          , est hébergée par SCALEWAY SAS (8 rue de la Ville l’Evêque, 75008
          Paris, France).
        </p>
      </Section>
      <Section title="Propriété intellectuelle" column limited justify>
        <p>
          Le présent site internet ainsi que l’ensemble de ses contenus
          (notamment les données, informations, photos, logos et marques) sont
          la propriété exclusive de KerNel Biomedical SAS ou de ses partenaires.
          Toute reproduction, représentation, traduction, adaptation ou
          citation, intégrale ou partielle, quel qu’en soit le procédé ou le
          support, est strictement interdite en dehors des cas prévus par la loi
          ou expressément autorisés par leur propriétaire. Photos non
          contractuelles.
        </p>
      </Section>
      <Section title="Données personnelles" column limited justify>
        <p>
          Vous pouvez visiter notre site sur Internet sans avoir à décliner
          votre identité ou à fournir des informations personnelles vous
          concernant. Cependant, nous pouvons parfois vous demander des
          informations pour traiter une commande, identifier une demande de
          support technique, établir une correspondance, fournir un abonnement
          ou soumettre une candidature à un poste.
        </p>
      </Section>
    </Page>
  );
}
